import { spotonProducts } from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Cincinnati Museum Center",
  pageDescription:
    "Discover how a major museum modernized its visitor experience and grew sales with SpotOn enterprise technology.",
  title: "Experiencing the past with futuristic technology",
  subTitle: `How the Cincinnati Museum Center updated its visitor experience with SpotOn.`,
  businessName: "Cincinnati Museum Center",
  industry: "Enterprise, amusement & attractions",
  location: "Cincinnati, Ohio",
  products: [spotonProducts.POS, spotonProducts.SELF_SERVICE_KIOSKS],
  description:
    "As the 17th most visited museum in the world, the Cincinnati Museum Center (CMC) welcomes more than one million visitors every year. With SpotOn technology, the CMC easily manages its large influxes of attendees and makes more sales with a better guest experience.",
  quoteData: {
    imgName: "cincinnati-museum.png",
    quote: `“The system is so user-friendly. We have visitors of all ages using our kiosks. It’s great to see a lot of the older crowd using the solution with a sense of pride that they can work the new technology."`,
    personName: "Caitlin Davis",
    personTitle: "General Manager, Cincinnati Museum Center",
  },
  goal: `Suffering frequent internet outages and mid-day 20-minute reboots with their legacy POS, the CMC needed a POS system with better connectivity, customizations, and payment processing. They also wanted a way to offer visitors a more user-controlled experience.`,
  solution: `SpotOn's enterprise-capable solutions expedited every part of CMC's operations, including retail, quick-service, and backend processes. And its self-service kiosks gave guests a fun and fast way to order and pay for purchases.`,
  // solutionReadMore: {
  //   text: `Read more about their story.`,
  //   url: `https://spoton.com/blog/chiva-loka-spoton-capital-funds/`,
  // },
  results: {
    title: `The results`,
    stats: [
      { title: `20%`, description: `increase in order size` },
      {
        title: `$5`,
        description: `increase in kiosk orders`,
      },
      { title: `40%`, description: `of guests opted to use kiosks` },
    ],
  },
};

export const relatedSuccessStories = [
  // {
  //   title: "La Chiva Loca: Selling Tacos, Turning Tables",
  //   imageName: "r-card-01.png",
  //   category: "Restaurants",
  //   tag: "Success stories",
  //   date: "2022-02-03",
  //   href: "",
  // },
  // {
  //   title: "La Chiva Loca: Selling Tacos, Turning Tables",
  //   imageName: "r-card-01.png",
  //   category: "Restaurants",
  //   tag: "Success stories",
  //   date: "2022-02-03",
  //   href: "",
  // },
  // {
  //   title: "La Chiva Loca: Selling Tacos, Turning Tables",
  //   imageName: "r-card-01.png",
  //   category: "Restaurants",
  //   tag: "Success stories",
  //   date: "2022-02-03",
  //   href: "",
  // },
];
